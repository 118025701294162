import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Aos from "aos";
import { createI18n } from "vue-i18n";
import komsg from "@/assets/scripts/i18n/message-ko";
import enmsg from "@/assets/scripts/i18n/message-en";
import jpmsg from "@/assets/scripts/i18n/message-jp";

import { registerScrollSpy } from "vue3-scroll-spy";

// importing AOS css style globally
import "aos/dist/aos.css";

const lang = localStorage.getItem("language") || "ko";

const i18n = createI18n({
  legacy: false,

  locale: lang, // 초기 언어 설정
  fallbackLocale: "ko", // 번역 메시지가 없는 경우 대체 언어 설정
  availableLocales: ["en", "en", "jp"],

  messages: {
    en: enmsg,
    ko: komsg,
    jp: jpmsg,
  },
  globalInjection: true,
  persistLocale: true,
});

const app = createApp(App);
registerScrollSpy(app);
app.use(i18n);
app.use(Aos.init());
app.use(router);

app.config.errorHandler = (err, instance, info) => {
  // Handle the error globally
  console.log("Global error:", err);
  console.log("Vue instance:", instance);
  console.log("Error info:", info);

  // Add code for UI notifications, reporting or other error handling logic
  router.push("/error");
};

app.mount("#app");
