
<template>
  <Header :cssname="stylename"></Header>
  <div class="btn_top" @click="moveTop">
    <a id="myTop" v-bind:title='$t("HEADER.0024")'>
      <span class="txt">{{ $t("HEADER.0025") }}</span>
      <span class="img"></span>
    </a>
  </div>

  <router-view></router-view>
  <Footer style="display: none;"></Footer>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import router from "@/router";

export default {
  name: "app",
  components: {
    Header,
    Footer,
  },
  computed: {
    styleUrl() {
      // 현재 언어 코드를 가져옵니다.
      const langCode = this.$i18n.locale;
      // 언어 코드에 맞는 SCSS 파일 경로를 반환합니다.
      return "@/assets/style/scss/style-${langCode}.scss";
    },
  },
  data: () => ({
    stylename: "",
  }),
  created() {
    window.addEventListener("resize", this.resizeApply);
  },

  mounted() {
    router.beforeEach((to, from, next) => {
      console.log("mounted : " + to.meta.cssname);
      this.stylename = to.meta.cssname;
      next();
    });

    // 컴포넌트가 마운트되면, 언어에 따른 SCSS 파일을 동적으로 로드합니다.
    const link = document.createElement("link");
    link.rel = "stylesheet/scss";
    link.href = this.styleUrl;
    document.head.appendChild(link);

    window.addEventListener("load", function () { 
      document.querySelector("Footer").style.display = "";
    }, false);

  },
  methods: {
    moveTop: function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    resizeApply: function () {
      var minWidth = 1200;
      var maxWidth = 820;
      var body = document.getElementsByTagName("body")[0];
      if (window.innerWidth < minWidth && window.innerWidth > maxWidth) {
        body.style.zoom = window.innerWidth / minWidth;
      } else body.style.zoom = 1;
    },
  },
};
</script>
<style lang = "scss">
@import "@/assets/style/scss/style.scss";
</style>

