import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/", //경로
    name: "Home", //페이지명
    component: () => import("@/router/home"), // 동적 import
    meta: {
      cssname: "",
    },
  },
  {
    path: "/error", //경로
    name: "Error", //페이지명
    component: () => import("@/router/error"), // 동적 import
    meta: {
      cssname: "",
    },
  },
  {
    path: "/:pathMatch(.*)*", //경로
    name: "404", //페이지명
    component: () => import("@/router/404"), // 동적 import
    meta: {
      cssname: "",
    },
  },

  {
    path: "/about",
    name: "About",
    component: () => import("@/views/about"),
    meta: {
      cssname: "header-colorfix",
    },
  },
  {
    path: "/business",
    name: "Business",
    component: () => import("@/views/business"),
    meta: {
      cssname: "header-colorfix",
    },
  },
  {
    path: "/customer",
    name: "Customer",
    component: () => import("@/views/customer"),
    meta: {
      cssname: "header-colorfix",
    },
  },
  {
    path: "/esg",
    name: "ESG",
    component: () => import("@/views/esg"),
    meta: {
      cssname: "header-colorfix",
    },
  },
  {
    path: "/recruit",
    name: "Recruit",
    component: () => import("@/views/recruit"),
    meta: {
      cssname: "header-colorfix",
    },
  },
  {
    path: "/solution",
    name: "Solution",
    component: () => import("@/views/solution"),
    meta: {
      cssname: "header-colorfix",
    },
  },
];
//라우터 생성
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        try {
          document.getElementById(el).scrollIntoView({ behavior: "smooth" });
        } catch (error) {
          console.log("[" + el + "name error]" + error);
        }
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
});

export default router;
