<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <nav class="main_nav">
    <ul class="menu">
      <li class="menu-item">
        <RouterLink :to="{path:'/about'}">
          <span>{{ $t("HEADER.0001") }}</span>
        </RouterLink>
      </li>
      <li class="menu-item">
        <RouterLink :to="{path:'/business'}">
          <span>{{ $t("HEADER.0002") }}</span>
        </RouterLink>
      </li>
      <li class="menu-item">
        <RouterLink :to="{path:'/solution'}">
          <span>{{ $t("HEADER.0003") }}</span>
        </RouterLink>
      </li>
      <li class="menu-item">
        <RouterLink :to="{path:'/recruit'}">
          <span>{{ $t("HEADER.0004") }}</span>
        </RouterLink>
      </li>
      <li class="menu-item">
        <RouterLink :to="{path:'/customer'}">
          <span>{{ $t("HEADER.0005") }}</span>
        </RouterLink>
      </li>
      <li class="menu-item">
        <RouterLink :to="{path:'/esg'}">
          <span>{{ $t("HEADER.0006") }}</span>
        </RouterLink>
      </li>
    </ul>
    <div class="sub_nav">
      <ul class="list">
        <li class="item">
          <RouterLink :to="{path:'/about', hash:'#aboutMiniOutlin'}">
            <span class="sub_tt">{{ $t("HEADER.0007") }}</span>
          </RouterLink>
        </li>
        <li class="item">
          <RouterLink :to="{path:'/about', hash:'#aboutMiniPhilosopy'}">
            <span class="sub_tt">{{ $t("HEADER.0008") }}</span>
          </RouterLink>
        </li>
        <li class="item">
          <RouterLink :to="{path:'/about', hash:'#aboutMiniHistory'}">
            <span class="sub_tt">{{ $t("HEADER.0009") }}</span>
          </RouterLink>
        </li>
      </ul>
      <ul class="list">
        <li class="item">
          <RouterLink :to="{path:'/business', hash:'#mBusiSi'}">
            <span class="sub_tt">{{ $t("HEADER.0010") }}</span>
            <span class="sub_tt sub_tt_m">{{ $t("HEADER.0022") }}</span>
          </RouterLink>
        </li>
        <li class="item">
          <RouterLink :to="{path:'/business', hash:'#mBusiIot'}">
            <span class="sub_tt">{{ $t("HEADER.0011") }}</span>
            <span class="sub_tt sub_tt_m">{{ $t("HEADER.0023") }}</span>
          </RouterLink>
        </li>
        <li class="item">
          <RouterLink :to="{path:'/business', hash:'#mBusiSmk'}">
            <span class="sub_tt">{{ $t("HEADER.0012") }}</span>
          </RouterLink>
        </li>
      </ul>
      <ul class="list">
        <li class="item">
          <RouterLink :to="{path:'/solution', hash:'#soliDocs'}">
            <span class="sub_tt">{{ $t("HEADER.0013") }}</span>
          </RouterLink>
        </li>
        <li class="item">
          <RouterLink :to="{path:'/solution', hash:'#soliWorks'}">
            <span class="sub_tt">{{ $t("HEADER.0014") }}</span>
          </RouterLink>
        </li>
        <li class="item">
          <RouterLink :to="{path:'/solution', hash:'#onClass'}">
            <span class="sub_tt">{{ $t("HEADER.0015") }}</span>
          </RouterLink>
        </li>
      </ul>
      <ul class="list">
        <li class="item">
          <RouterLink :to="{path:'/recruit', hash:'#rcWelfare'}">
            <span class="sub_tt">{{ $t("HEADER.0016") }}</span>
          </RouterLink>
        </li>
        <li class="item">
          <RouterLink :to="{path:'/recruit', hash:'#rcRecruit'}">
            <span class="sub_tt">{{ $t("HEADER.0017") }}</span>
          </RouterLink>
        </li>
      </ul>
      <ul class="list">
        <li class="item">
          <RouterLink :to="{path:'/customer', hash:'#csApply'}">
            <span class="sub_tt">{{ $t("HEADER.0018") }}</span>
          </RouterLink>
        </li>
      </ul>
      <ul class="list">
        <li class="item">
          <RouterLink :to="{path:'/esg', hash:'#esgEsg'}">
            <span class="sub_tt">{{ $t("HEADER.0019") }}</span>
          </RouterLink>
        </li>
        <li class="item">
          <RouterLink :to="{path:'/esg', hash:'#esgLaw'}">
            <span class="sub_tt">{{ $t("HEADER.0020") }}</span>
          </RouterLink>
        </li>
        <li class="item">
          <RouterLink :to="{path:'/esg', hash:'#esgHuman'}">
            <span class="sub_tt">{{ $t("HEADER.0021") }}</span>
          </RouterLink>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped></style>
