<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <footer id="footerKo" class="footer">
    <!-- S: minisoft information -->
    <section class="info_contents" ref="infoContents">
      <h1 class="logo_minisoft" id="infoLogo">
        <svg width="171" height="60" viewBox="0 0 171 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M97.8659 10.6258C98.3005 8.45344 99.8045 7.39108 102.441 7.38632C104.287 7.38632 105.781 7.97228 106.68 8.51537L106.865 8.62495L109.033 5.05198L108.877 4.95194C107.652 4.09919 105.537 3.45606 102.534 3.45606C96.9577 3.4513 92.7778 6.99567 91.9819 11.1784C91.9233 11.4357 91.8891 11.7072 91.8647 11.974C91.6742 14.3274 92.7925 16.8237 96.8844 18.4053C99.7215 19.5058 100.723 20.5348 100.61 22.0879C100.605 22.226 100.581 22.3689 100.552 22.5166C100.107 24.7938 98.2908 26.0181 95.4293 26.0277C93.227 26.0277 91.6938 25.4417 90.8099 24.8653L90.6195 24.7462L88.2756 28.6955L88.4368 28.8003C89.9066 29.7197 92.0453 30.2819 95.6002 30.2819H95.61C101.211 30.2819 105.918 26.9328 106.812 22.2641C106.87 21.9354 106.919 21.6115 106.944 21.2732C107.134 18.9865 106.196 16.4759 101.426 14.6228C99.1599 13.7367 97.6609 12.8172 97.8073 11.0212C97.8171 10.8926 97.8415 10.764 97.8659 10.6258Z"
            fill="white" />
          <path
            d="M130.612 17.8622C130.998 12.7219 128.488 9.02028 122.506 9.01076C115.875 9.00123 111.763 13.5746 110.625 19.6439C110.503 20.2442 110.43 20.8396 110.381 21.4161C109.996 26.523 112.535 30.277 118.497 30.2818H118.516C125.133 30.2818 129.24 25.6798 130.368 19.6915C130.48 19.0627 130.563 18.4529 130.612 17.8622ZM125.069 17.4239C125.011 18.1147 124.889 18.8578 124.733 19.601C123.995 23.0263 122.677 26.0514 119.122 26.0323C116.378 26.0276 115.767 24.1696 115.963 21.6162C116.021 20.9873 116.129 20.3204 116.27 19.6439C117.017 16.19 118.414 13.0268 121.93 13.0411C124.698 13.0506 125.26 14.8942 125.069 17.4239Z"
            fill="white" />
          <path
            d="M163.938 13.5034H169.393L170.179 9.38738H164.73L165.74 4.22803H161.36C160.594 4.22803 159.959 4.75683 159.803 5.46189L159.046 9.39215H155.52C154.807 9.39215 154.202 9.84949 154.001 10.4831L153.42 13.5082H158.255C158.255 13.5082 156.057 25.0989 156.038 25.3085C155.847 27.9287 157.41 30.2582 161.683 30.2582C162.957 30.2582 163.968 30.0296 164.51 29.839L165.14 29.6151L165.848 25.9135L165.037 26.166C164.583 26.2804 164.251 26.3423 163.802 26.3423C162.268 26.3137 161.824 25.6467 161.878 24.4462C161.892 24.2938 163.938 13.5034 163.938 13.5034Z"
            fill="white" />
          <path
            d="M40.085 30.0008H44.4211H44.4358C45.2415 30.0008 45.9153 29.4434 46.0813 28.705L49.729 9.38721H43.9816L40.085 30.0008Z"
            fill="white" />
          <path
            d="M29.0736 9.00146C25.2649 9.00146 22.628 10.0591 20.8262 11.3977C19.249 9.86374 16.91 9.00146 14.4343 9.00146C5.16138 9.00146 2.8224 15.2708 2.60266 16.5666L0 30.001H4.33614H4.35079C5.16138 30.001 5.845 29.4388 6.00126 28.6861L7.61267 20.2253C8.25723 17.0049 9.6196 13.3557 13.609 13.3557C15.4548 13.3557 16.7733 14.5705 16.8221 16.3713C16.866 17.9625 16.5633 19.4298 16.2996 20.8923L14.6394 29.9962H18.9755H18.9902C19.8056 29.9962 20.4941 29.4245 20.6455 28.6623L20.6504 28.6671C20.6504 28.6671 22.4229 19.5774 22.5255 19.0201C23.2726 16.1569 24.7473 13.351 28.2484 13.351C30.0942 13.351 31.4175 14.5658 31.4615 16.3665C31.5103 17.9577 31.2027 19.425 30.9439 20.8875L29.2836 29.9914H33.6246H33.6393C34.4548 29.9914 35.1433 29.4198 35.2946 28.6623L36.8621 20.5922C37.1258 19.2535 37.5115 17.5385 37.4676 15.9044C37.3504 11.6455 33.4098 9.00146 29.0736 9.00146Z"
            fill="white" />
          <path
            d="M76.6833 30.0008H81.0244H81.039C81.8447 30.0008 82.5186 29.4434 82.6846 28.705L86.3371 9.38721H80.58L76.6833 30.0008Z"
            fill="white" />
          <path
            d="M65.6721 9.00146C56.3992 9.00146 54.0651 15.2708 53.8405 16.5666L51.2378 30.001H55.5739H55.5886C56.3992 30.001 57.0828 29.4388 57.2391 28.6909L58.8505 20.2253C59.495 17.0049 60.8574 13.3557 64.8468 13.3557C66.6926 13.3557 68.011 14.5705 68.0648 16.3713C68.1087 17.9625 67.806 19.4298 67.5423 20.8923L65.882 29.9962H70.2182H70.2377C71.0532 29.9962 71.7417 29.4245 71.8931 28.6671L73.4605 20.5969C73.7242 19.2583 74.11 17.5432 74.066 15.9092C73.9439 11.6455 70.0082 9.00146 65.6721 9.00146Z"
            fill="white" />
          <path
            d="M140.314 7.14825L139.889 9.38731H134.728L133.942 13.5034H139.103L130.26 59.9995H135.939L144.797 13.5034H149.045H149.06C149.797 13.5034 150.398 12.9936 150.534 12.3219L151.106 9.38731H145.583L146.022 7.07679C146.457 4.92349 148.044 4.45662 150.842 4.45662C151.443 4.45662 151.931 3.90876 152.048 3.29898H152.053L152.6 0.39297C152.6 0.39297 151.155 0.173828 149.856 0.173828C144.758 0.173828 141.13 2.81306 140.314 7.14825Z"
            fill="white" />
        </svg>
        <span class="blind">{{ $t("FOOTER.0001") }}</span>
      </h1>
      <!-- 개인정보처리방침 -->
      <article class="if_item" id="privacyPolicy">
        <div class="title_wrap">
          <h6 class="tt">{{ $t("FOOTER.0002") }}</h6>
          <p class="sub_tt">{{ $t("FOOTER.0003") }}</p>
        </div>
        <div class="detail">
          <p>
            <span>
              {{ $t("FOOTER.0004") }}
            </span>
            <span>{{ $t("FOOTER.0005") }}</span>
          </p>
          <ul>
            <li>
              <p>
                {{ $t("FOOTER.0006") }}
              </p>
            </li>
            <li>
              <p>
                <span>
                  {{ $t("FOOTER.0007") }}
                </span>
                <span>
                  {{ $t("FOOTER.0008") }}
                </span>
              </p>
            </li>
            <li>
              <p>
                {{ $t("FOOTER.0009") }}
              </p>
            </li>
          </ul>
        </div>
        <div class="btn_info_close" type="button" @click="eventHandler.closeBottomLayer">
          <div class="bg"></div>
          <span class="txt">{{ $t("FOOTER.0010") }}</span>
        </div>
      </article>
      <!-- 오시는길 -->
      <article class="if_item" id="miniMap">
        <div class="title_wrap">
          <h6 class="tt">{{ $t("FOOTER.0011") }}</h6>
          <p class="sub_tt">{{ $t("FOOTER.0012") }}</p>
        </div>
        <div class="detail map_wrap">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.8017526111007!2d126.991103966075!3d37.48900438632092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cbcc65ca3bd15%3A0xce113745afe71285!2zKOyjvCnrr7jri4jshoztlITtirg!5e0!3m2!1sko!2skr!4v1680669814481!5m2!1sko!2skr"
            width="600" height="450" style="border: 0" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="btn_info_close" type="button" @click="eventHandler.closeBottomLayer">
          <div class="bg"></div>
          <span class="txt">{{ $t("FOOTER.0013") }}</span>
        </div>
      </article>
      <!-- 이메일무단수집거부 -->
      <article class="if_item" id="miniEmail">
        <div class="title_wrap">
          <h6 class="tt">{{ $t("FOOTER.0014") }}</h6>
          <p class="sub_tt">{{ $t("FOOTER.0015") }}</p>
        </div>
        <div class="detail">
          <p>
            <span v-html="$t('HOME.0018')"></span>
          </p>
        </div>
        <div class="btn_info_close" type="button">
          <div class="bg"></div>
          <span class="txt">{{ $t("FOOTER.0016") }}</span>
        </div>
      </article>
    </section>
    <!-- E: minisoft information -->
    <h1 class="logo_minisoft">
      <svg width="171" height="60" viewBox="0 0 171 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M97.8659 10.6258C98.3005 8.45344 99.8045 7.39108 102.441 7.38632C104.287 7.38632 105.781 7.97228 106.68 8.51537L106.865 8.62495L109.033 5.05198L108.877 4.95194C107.652 4.09919 105.537 3.45606 102.534 3.45606C96.9577 3.4513 92.7778 6.99567 91.9819 11.1784C91.9233 11.4357 91.8891 11.7072 91.8647 11.974C91.6742 14.3274 92.7925 16.8237 96.8844 18.4053C99.7215 19.5058 100.723 20.5348 100.61 22.0879C100.605 22.226 100.581 22.3689 100.552 22.5166C100.107 24.7938 98.2908 26.0181 95.4293 26.0277C93.227 26.0277 91.6938 25.4417 90.8099 24.8653L90.6195 24.7462L88.2756 28.6955L88.4368 28.8003C89.9066 29.7197 92.0453 30.2819 95.6002 30.2819H95.61C101.211 30.2819 105.918 26.9328 106.812 22.2641C106.87 21.9354 106.919 21.6115 106.944 21.2732C107.134 18.9865 106.196 16.4759 101.426 14.6228C99.1599 13.7367 97.6609 12.8172 97.8073 11.0212C97.8171 10.8926 97.8415 10.764 97.8659 10.6258Z"
          fill="white" />
        <path
          d="M130.612 17.8622C130.998 12.7219 128.488 9.02028 122.506 9.01076C115.875 9.00123 111.763 13.5746 110.625 19.6439C110.503 20.2442 110.43 20.8396 110.381 21.4161C109.996 26.523 112.535 30.277 118.497 30.2818H118.516C125.133 30.2818 129.24 25.6798 130.368 19.6915C130.48 19.0627 130.563 18.4529 130.612 17.8622ZM125.069 17.4239C125.011 18.1147 124.889 18.8578 124.733 19.601C123.995 23.0263 122.677 26.0514 119.122 26.0323C116.378 26.0276 115.767 24.1696 115.963 21.6162C116.021 20.9873 116.129 20.3204 116.27 19.6439C117.017 16.19 118.414 13.0268 121.93 13.0411C124.698 13.0506 125.26 14.8942 125.069 17.4239Z"
          fill="white" />
        <path
          d="M163.938 13.5034H169.393L170.179 9.38738H164.73L165.74 4.22803H161.36C160.594 4.22803 159.959 4.75683 159.803 5.46189L159.046 9.39215H155.52C154.807 9.39215 154.202 9.84949 154.001 10.4831L153.42 13.5082H158.255C158.255 13.5082 156.057 25.0989 156.038 25.3085C155.847 27.9287 157.41 30.2582 161.683 30.2582C162.957 30.2582 163.968 30.0296 164.51 29.839L165.14 29.6151L165.848 25.9135L165.037 26.166C164.583 26.2804 164.251 26.3423 163.802 26.3423C162.268 26.3137 161.824 25.6467 161.878 24.4462C161.892 24.2938 163.938 13.5034 163.938 13.5034Z"
          fill="white" />
        <path
          d="M40.085 30.0008H44.4211H44.4358C45.2415 30.0008 45.9153 29.4434 46.0813 28.705L49.729 9.38721H43.9816L40.085 30.0008Z"
          fill="white" />
        <path
          d="M29.0736 9.00146C25.2649 9.00146 22.628 10.0591 20.8262 11.3977C19.249 9.86374 16.91 9.00146 14.4343 9.00146C5.16138 9.00146 2.8224 15.2708 2.60266 16.5666L0 30.001H4.33614H4.35079C5.16138 30.001 5.845 29.4388 6.00126 28.6861L7.61267 20.2253C8.25723 17.0049 9.6196 13.3557 13.609 13.3557C15.4548 13.3557 16.7733 14.5705 16.8221 16.3713C16.866 17.9625 16.5633 19.4298 16.2996 20.8923L14.6394 29.9962H18.9755H18.9902C19.8056 29.9962 20.4941 29.4245 20.6455 28.6623L20.6504 28.6671C20.6504 28.6671 22.4229 19.5774 22.5255 19.0201C23.2726 16.1569 24.7473 13.351 28.2484 13.351C30.0942 13.351 31.4175 14.5658 31.4615 16.3665C31.5103 17.9577 31.2027 19.425 30.9439 20.8875L29.2836 29.9914H33.6246H33.6393C34.4548 29.9914 35.1433 29.4198 35.2946 28.6623L36.8621 20.5922C37.1258 19.2535 37.5115 17.5385 37.4676 15.9044C37.3504 11.6455 33.4098 9.00146 29.0736 9.00146Z"
          fill="white" />
        <path
          d="M76.6833 30.0008H81.0244H81.039C81.8447 30.0008 82.5186 29.4434 82.6846 28.705L86.3371 9.38721H80.58L76.6833 30.0008Z"
          fill="white" />
        <path
          d="M65.6721 9.00146C56.3992 9.00146 54.0651 15.2708 53.8405 16.5666L51.2378 30.001H55.5739H55.5886C56.3992 30.001 57.0828 29.4388 57.2391 28.6909L58.8505 20.2253C59.495 17.0049 60.8574 13.3557 64.8468 13.3557C66.6926 13.3557 68.011 14.5705 68.0648 16.3713C68.1087 17.9625 67.806 19.4298 67.5423 20.8923L65.882 29.9962H70.2182H70.2377C71.0532 29.9962 71.7417 29.4245 71.8931 28.6671L73.4605 20.5969C73.7242 19.2583 74.11 17.5432 74.066 15.9092C73.9439 11.6455 70.0082 9.00146 65.6721 9.00146Z"
          fill="white" />
        <path
          d="M140.314 7.14825L139.889 9.38731H134.728L133.942 13.5034H139.103L130.26 59.9995H135.939L144.797 13.5034H149.045H149.06C149.797 13.5034 150.398 12.9936 150.534 12.3219L151.106 9.38731H145.583L146.022 7.07679C146.457 4.92349 148.044 4.45662 150.842 4.45662C151.443 4.45662 151.931 3.90876 152.048 3.29898H152.053L152.6 0.39297C152.6 0.39297 151.155 0.173828 149.856 0.173828C144.758 0.173828 141.13 2.81306 140.314 7.14825Z"
          fill="white" />
      </svg>
      <span class="blind">{{ $t("FOOTER.0001") }}</span>
    </h1>
    <ul class="site_info">
      <li>
        <a href="#miniMap" class="txt btn_info" id="btnMiniMap">{{ $t("FOOTER.0011") }}</a>
      </li>
      <li>
        <a href="#privacyPolicy" class="txt txt-strong btn_info" id="btnPrivacyPolicy">{{ $t("FOOTER.0020") }}</a>
      </li>
      <li id="miniBrochure">
        <a :href='$t("FILE.0001")' :download='$t("FOOTER.0028")' class="txt txt-strong mini_brochure">{{ $t("FOOTER.0021") }}</a>
      </li>
      <!-- <li>
        <a href="#miniEmail" class="txt btn_info" id="btnMiniEmail"
          >이메일무단수집거부</a
        >
      </li> -->
      <li>
        <RouterLink :to="{path:'/customer'}" class="txt btn_inquiry">{{ $t("FOOTER.0022") }}</RouterLink>
      </li>
    </ul>
    <ul class="mini_info">
      <li>
        <address>{{ $t("FOOTER.0023") }}</address>
      </li>
      <li>
        <span>{{ $t("FOOTER.0024") }}</span>
      </li>
      <li>
        <span>{{ $t("FOOTER.0025") }}</span>
      </li>
      <li class="ft_email">
        <a href="mailto:sales@minisoft.co.kr">
          <span>{{ $t("FOOTER.0026") }}</span>
        </a>
      </li>
    </ul>
    <small class="txt_copyright">{{ $t("FOOTER.0027") }}</small>
  </footer>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  setup() {
    const infoContents = ref(null); //document.querySelector(".info_contents");

    const eventHandler = {
      closeBottomLayer(e) {
        infoContents.value.style.display = "none";
        document.documentElement.style.overflowY = "scroll";
        const ifItems = infoContents.value.querySelectorAll(".if_item");
        ifItems.forEach(function (item) {
          item.style.display = "none";
          item.style.bottom = "-200%";
        });
      },
    };

    onMounted(() => {
      const btnInfos = document.querySelectorAll(
        ".footer .site_info .btn_info"
      );

      const miniMap = document.querySelector("#miniMap");
      const privacyPolicy = document.querySelector("#privacyPolicy");

      btnInfos.forEach((btn) => {
        btn.addEventListener("click", (e) => {
          infoContents.value.style.display = "block";
          document.documentElement.style.overflow = "hidden";
          document.body.style.overflow = "hidden";

          if (e.target.hash == "#miniMap") {
            miniMap.style.display = "flex";
            miniMap.style.bottom = 0;
            privacyPolicy.style.display = "none";
          } else if (e.target.hash == "#privacyPolicy") {
            miniMap.style.display = "none";
            privacyPolicy.style.display = "flex";
            privacyPolicy.style.bottom = 0;
          }
        });
      });
    });
    return { eventHandler, infoContents };
  },
};
</script>

<style scoped></style>